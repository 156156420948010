<template>
  <div class="wrapper">
      <div class="video_wrapper">
        <video playsinline autoplay muted :src="require(`../assets/videos/${videoURL}.webm`)" >
        </video>
      </div>
      <div class="controls">
        <div class="select">
            <label for="product">Select Service</label>&nbsp;
            <select v-model="product">
                <option v-for="(product, index) in products" :value="product.Service" :key="index">{{product.Service}}</option>
            </select>
        </div>
        <h4>Profit Margin: {{animatedProfit.toFixed(2)}}%</h4>
        <div class="refresh_wrapper">
            <a class="refresh" @click="reload()"><font-awesome-icon :icon="['fas', 'redo']"/></a>
        </div>
      </div>

  </div>
</template>

<script scoped>
import { GoogleSpreadsheet } from 'google-spreadsheet'

export default {
    data(){
        return {
            COLUMNS: 5,
            sheetPageNumber: 1,
            SHEETID: '1ciFLIYTUAzWoQ9-9IiErEvwjrtflyxOeF_zwcw_F-Kw',
            GOOGLE_API_KEY:'AIzaSyBlHgLl9PTcKPCQHrkXzN8v0Sh_E6908ao',
            products:[{
                Service:"Overall"
            }],
            product:"Overall",
            profit_margin:0,
            unset:true,
            animatedProfit:0,
            interval:null
        }
    },
    mounted(){
        var _self = this
        _self.loadData()
        setInterval(()=>{
            _self.loadData()
        },50000)
    },
    computed:{
        avgMargin:function(){
            if((this.product === 'Overall')){
                if(this.unset)
                    return 0
                else
                    return this.products.filter(product =>
                        product.Service !== "Overall"
                    ).map((elem) =>
                        parseFloat(elem.Profit.replace("%",""))
                    ).reduce((prev, next) =>
                        prev + next
                    )/this.products.filter(product =>
                        product.Service !== "Overall"
                    ).length;
            }else{
                return this.products.filter(product =>
                    product.Service === this.product
                ).map((elem) =>
                    parseFloat(elem.Profit.replace("%",""))
                )
            }
        },
        selectedProduct:function(){
            return this.products.filter(element => element.Service === this.product)
        },
        videoURL:function(){
            if(!this.unset){
                if(this.avgMargin >= 0 && this.avgMargin < 20){
                    return 'All'
                }else if(this.avgMargin > 20 && this.avgMargin < 60){
                    return 'Half'
                }else{
                    return 'None'
                }
            }else return 'Landing'
        }
    },
    watch:{
        avgMargin:function(val){
            this.animateValue(0, val, 500)
            this.$forceUpdate
        }
    },
    methods:{
        async loadData(){
            const doc = new GoogleSpreadsheet(this.SHEETID);

            // Initialize Auth - see more available options at https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
            doc.useApiKey(this.GOOGLE_API_KEY);

            await doc.loadInfo(); // loads document properties and worksheets

            const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]

            const rows = await sheet.getRows(); // can pass in { limit, offset }
            this.products = [{
                Service:"Overall"
            }]
            rows.forEach(element => {
                this.products.push({
                    Service: element.Service,
                    Profit:element.ProfitMargin
                })
            });


            if(this.unset) this.unset = false
        },

        animateValue(start, end, duration) {
            //console.log(end)
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                this.animatedProfit = progress * (end - start) + start;
                if (progress === 1) {
                    clearInterval(this.interval);
                }
            };
            this.interval = setInterval(function(){
                step(Date.now())
            }, 5)

        },
        reload(){
            window.location.reload()
        }
    }
}
</script>

<style lang="scss" scoped>
    .wrapper{
        width: 100%;
        position: relative;
        margin: 0px auto;
        max-height: 600px;
        max-width: 800px;
        .controls{
            position: absolute;
            width: 100%;
            bottom:0px;
            left:0px;
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            justify-content: center;
            h4{
                color: #FFF;
                z-index: 100;
                position: relative;
                flex:1;
            }
            .select{
                flex:1;
                z-index: 100;
                position: relative;
                label{
                    color: #FFF;
                }
            }
            .refresh_wrapper{
                .refresh{
                    display: block;
                    font-size:10px;
                    height: 20px;
                    width: 20px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFF;
                    background: #333;
                    border-radius: 10px;
                    box-shadow:1px 2px 5px #333;
                    cursor: pointer;
                    margin-right: 20px;
                }
            }
        }
        .video_wrapper{
            video{
                width: 100%;
                border-radius: 25px;
            }
            margin: 0px auto;
            width: 100%;
            overflow: hidden;
            position: relative;
            top: 0px;
            left: 0px;
            z-index: 0;
        }

    }
</style>
