<template>
    <div class="wrapper">
      <div id="container"></div>
      <h2 v-if="loading < 100" class="loading">Loading:{{loading}}%</h2>
      <div class="controls">
        <select v-model="product">
            <option v-for="(product, index) in products" :value="product.Service" :key="index">{{product.Service}}</option>
        </select>
        <h4>Profit Margin: {{animatedProfit.toFixed(2)}}%</h4>
        <div class="refresh_wrapper">
            <a class="refresh" @click="reload()"><font-awesome-icon :icon="['fas', 'redo']"/></a>
        </div>
      </div>
    </div>
</template>

<script>
import * as Three from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import {SimplifyModifier} from 'three/examples/jsm/modifiers/SimplifyModifier';


// import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';

import { GoogleSpreadsheet } from 'google-spreadsheet'

export default {
    name: 'ThreeTest',
    data() {
        return {
            camera: null,
            scene: null,
            renderer: null,
            mesh: null,
            controls: null,
            COLUMNS: 5,
            sheetPageNumber: 1,
            SHEETID: '1ciFLIYTUAzWoQ9-9IiErEvwjrtflyxOeF_zwcw_F-Kw',
            GOOGLE_API_KEY:'AIzaSyBlHgLl9PTcKPCQHrkXzN8v0Sh_E6908ao',
            products:[{
                Service:"Overall"
            }],
            product:"Overall",
            profit_margin:0,
            totalIncome:0,
            totalCosts:0,
            totalProfit:0,
            unset:true,
            animatedProfit:0,
            interval:null,
            transitionInterval:null,
            clock:null,
            clipPlanes:[],
            helpers:null,
            planeObjects:[],
            Quaternion0:null,
            Quaternion1:null,
            planeNormal:null,
            forwardVector:null,
            size: 30,
            loading:0
        }
    },
    methods: {
        init: async function() {
            let container = document.getElementById('container');

            this.camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.1, 2000);
            this.camera.position.z = 150;

            this.scene = new Three.Scene();

            this.scene.add( new Three.AmbientLight( 0xffffff, 0.5 ) );

            const light = new Three.AmbientLight( 0x404040 ); // soft white light
            this.scene.add( light );

            const directionalLight = new Three.DirectionalLight( 0xffffff, 0.8 );
            this.scene.add( directionalLight );

            const directionalLight2 = new Three.DirectionalLight( 0xffffff, 0.8 );
            directionalLight2.position.set(1,0,0)
            this.scene.add( directionalLight2 );


            const directionalLight3 = new Three.DirectionalLight( 0xffffff, 0.8 );
            directionalLight2.position.set(0,0,1)
            this.scene.add( directionalLight3 );

            const directionalLight4 = new Three.DirectionalLight( 0xffffff, 0.8 );
            directionalLight2.position.set(0,-1,0)
            this.scene.add( directionalLight4 );

            this.clock = new Three.Clock();

            this.renderer = new Three.WebGLRenderer({antialias: true, alpha: true });
            this.renderer.setClearColor( 0x000000, 0 );
            this.renderer.outputEncoding = Three.sRGBEncoding;
            this.renderer.localClippingEnabled = true;
            this.renderer.setSize(container.clientWidth, container.clientHeight);
            this.controls = new OrbitControls( this.camera, this.renderer.domElement);
            //this.controls.addEventListener( 'change', this.renderer);
            this.controls.autoRotate = true;
            await this.loadModel();
            container.appendChild(this.renderer.domElement);

        },

        async loadModel(){
            var _self = this

            const loader = new GLTFLoader();

            // Optional: Provide a DRACOLoader instance to decode compressed mesh data
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath( '/3D/GlazedDonut_OBJ/' );
            loader.setDRACOLoader( dracoLoader );

            // Load a glTF resource
            await loader.load(
                // resource URL
                '/3D/GlazedDonut_OBJ/GlazedDonut.glb',
                // called when the resource is loaded
                function ( gltf ) {
                    _self.mesh = gltf.scene
                    _self.mesh.position = new Three.Vector3( 0, 0, 0 )
                    _self.planeNormal = new Three.Vector3(1, 0, 0).normalize()
                    _self.forwardVector = new Three.Vector3(0, 0, -1);
                    _self.clipPlanes = [
                        new Three.Plane( _self.planeNormal, _self.size ),
                    ];
                    _self.mesh.children.forEach(mesh =>{
                        mesh.material.side = Three.DoubleSide,
                        mesh.material.clippingPlanes = _self.clipPlanes
                        mesh.material.clipIntersection = 0
                    })
                    _self.scene.add( _self.mesh );
                    let geomArray = []
                     _self.mesh.children.forEach(mesh =>{
                        geomArray.push(mesh.geometry)
                    })
                    // Plane helpers
                    _self.helpers = new Three.Group();
                    _self.helpers.add( new Three.PlaneHelper( _self.clipPlanes[ 0 ], 100, 0xff0000 ) );
                    _self.helpers.visible = false;

                    _self.scene.add( _self.helpers );

                    let mergeGeometry = BufferGeometryUtils.mergeBufferGeometries(geomArray)

                    var modifier = new SimplifyModifier();
                    var simplified = modifier.modify(mergeGeometry, 100);

                    const stencilGroup = _self.createPlaneStencilGroup( simplified, _self.clipPlanes[0], 1 );


                    _self.scene.add(stencilGroup)

                    gltf.animations; // Array<THREE.AnimationClip>
                    gltf.scene; // THREE.Group
                    gltf.scenes; // Array<THREE.Group>
                    gltf.cameras; // Array<THREE.Camera>
                    gltf.asset; // Object

                },
                // called while loading is progressing
                function ( xhr ) {

                    console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

                    _self.loading = xhr.loaded / xhr.total * 100

                },
                // called when loading has errors
                function ( error ) {

                    console.log( 'An error happened', error);

                }
            );
        },

        createPlaneStencilGroup(geometry, plane, renderOrder) {

            const group = new Three.Group();
            const baseMat = new Three.MeshBasicMaterial();
            baseMat.depthWrite = false;
            baseMat.depthTest = false;
            baseMat.colorWrite = false;
            baseMat.stencilWrite = true;
            baseMat.stencilFunc = Three.AlwaysStencilFunc;

            // back faces
            const mat0 = baseMat.clone();
            mat0.side = Three.BackSide;
            mat0.clippingPlanes = [ plane ];
            mat0.stencilFail = Three.IncrementWrapStencilOp;
            mat0.stencilZFail = Three.IncrementWrapStencilOp;
            mat0.stencilZPass = Three.IncrementWrapStencilOp;

            const mesh0 = new Three.Mesh( geometry, mat0 );
            mesh0.renderOrder = renderOrder;

            group.add( mesh0 );

            // front faces
            const mat1 = baseMat.clone();
            mat1.side = Three.FrontSide;
            mat1.clippingPlanes = [ plane ];
            mat1.stencilFail = Three.DecrementWrapStencilOp;
            mat1.stencilZFail = Three.DecrementWrapStencilOp;
            mat1.stencilZPass = Three.DecrementWrapStencilOp;

            const mesh1 = new Three.Mesh( geometry, mat1 );
            mesh1.renderOrder = renderOrder;

            group.add( mesh1 );


            // const texture = new Three.TextureLoader().load('/3D/donuttexture/texture.jpg');
            // texture.wrapS = Three.RepeatWrapping;
            // texture.wrapT = Three.RepeatWrapping;
            // texture.repeat.set( 62, 62);
            // //const normal = new Three.TextureLoader().load( '3D/donuttexture/NormalMap.png' );
            // const occlusion = new Three.TextureLoader().load( '3D/donuttexture/AmbientOcclusionMap.png' );
            // const displacement = new Three.TextureLoader().load( '3D/donuttexture/DisplacementMap.png' );
            //const specular = new Three.TextureLoader().load( '3D/donuttexture/SpecularMap.png' );

            const mat2 = new Three.MeshBasicMaterial({ color: 0xFFFF8866 , wireframe:false});
            mat2.stencilWrite = true;
            mat2.stencilRef = 0;
            mat2.stencilFunc = Three.NotEqualStencilFunc;
            mat2.stencilFail = Three.ReplaceStencilOp;
            mat2.stencilZFail = Three.ReplaceStencilOp;
            mat2.stencilZPass = Three.ReplaceStencilOp;

            var planeGeom = new Three.PlaneBufferGeometry();

            const mesh2 = new Three.Mesh( planeGeom, mat2 );
            mesh2.scale.setScalar(10000);
            plane.coplanarPoint(mesh2.position);
            mesh2.quaternion.setFromUnitVectors(this.forwardVector, this.planeNormal);
            mesh2.renderOrder = renderOrder;

            group.add( mesh2 );

            return group;

		},
        animate: function() {
            requestAnimationFrame(this.animate);
            this.controls.update()
            //if(this.mesh)this.mesh.rotation.y += this.clock.getDelta()
            this.renderer.render(this.scene, this.camera);
        },
        async loadData(){
            const doc = new GoogleSpreadsheet(this.SHEETID);

            // Initialize Auth - see more available options at https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
            doc.useApiKey(this.GOOGLE_API_KEY);

            await doc.loadInfo(); // loads document properties and worksheets

            const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]

            const rows = await sheet.getRows(); // can pass in { limit, offset }

            this.products = [{
                Service:"Overall"
            }]
            rows.forEach(element => {
                this.products.push({
                    Service: element.Service,
                    Profit: element.ProfitMargin
                })
            });

            if(this.unset) this.unset = false
        },

        animateValue(start, end, duration) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                this.animatedProfit = progress * (end - start) + start;
                if (progress === 1) {
                    clearInterval(this.interval)
                }
            };
            this.interval = setInterval(function(){
                step(Date.now())
            }, 5)
        },
        animateClipping(start, end, duration) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);

                if(this.clipPlanes.length){

                    var plane1 = this.clipPlanes[0];
                    plane1.constant = - (this.size - 2 * this.size * ((progress/100 * (end - start))))

                }

                if (progress === 1) {
                    clearInterval(this.transitionInterval)
                }
            };
            this.transitionInterval = setInterval(function(){
                step(Date.now())
            }, 5)
        },
        reload(){
            window.location.reload()
        }
    },
    async mounted() {
        await this.init();
        this.animate();
        this.loadData();
        var _self = this
        setInterval(()=>{
            _self.loadData()
        },50000)
    },
    computed:{
        avgMargin:function(){
            if((this.product === 'Overall')){
                if(this.unset)
                    return 0
                else
                    return this.products.filter(product =>
                        product.Service !== "Overall"
                    ).map((elem) =>
                        parseFloat(elem.Profit.replace("%",""))
                    ).reduce((prev, next) =>
                        prev + next
                    )/this.products.filter(product =>
                        product.Service !== "Overall"
                    ).length;
            }else{
                return this.products.filter(product =>
                    product.Service === this.product
                ).map((elem) =>
                    parseFloat(elem.Profit.replace("%",""))
                )
            }
        },
        selectedProduct:function(){
            return this.products.filter(element => element.Service === this.product)
        }
    },
    watch:{
        avgMargin:function(val){
            //console.log("margin:"+val)
            this.animateValue(0, val, 500)
            this.animateClipping(0, val, 500)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper{
    width: 100%;
    position: relative;
    margin: 0px auto;
    max-height: 600px;
    max-width: 800px;
    border: 1px solid #333;
    border-radius: 25px;
    overflow: hidden;
    .loading{
        position: absolute;
        color:purple;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        z-index: 100;
    }
    .controls{
        padding: 30px;
        position: absolute;
        width: calc(100% - 60px);
        bottom:0px;
        left:0px;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
        background:#333;
        h4{
            color: #FFF;
            z-index: 100;
            position: relative;
            flex:1;
        }
        .select{
            flex:1;
            z-index: 100;
            position: relative;
            label{
                color: #FFF;
            }
        }
        .refresh_wrapper{
            .refresh{
                display: block;
                font-size:10px;
                height: 20px;
                width: 20px;
                line-height: 20px;
                text-align: center;
                color: #FFF;
                background: #3D3D3D;
                border-radius: 10px;
                box-shadow:1px 2px 5px #333;
                cursor: pointer;
                margin-right: 20px;
            }
        }
    }
    #container{
       width: 100%;
       height: 600px;
       margin: 0px auto;
    }
}
</style>
