import Vue from 'vue'
import VueRouter from 'vue-router'
import Video from '../views/video.vue'
import ThreeObj from '../views/threejs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Video',
    component: Video
  },
  {
    path: '/threeJS',
    name: 'ThreeJs',
    component: ThreeObj
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
